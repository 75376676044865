import React from "react";
import "./styles.scss";
import GeneralWelcomeComponents from "..";
import { InputMask } from '@react-input/mask';
import { FormHelperText } from "@mui/material";
import { removerCaracteresEspeciais } from "../../../../services/utils.jsx";

function CpfForm({ changeCustomerData, invalidCPF, setInvalidCPF, customerData, loadingForm }) {
  return (
	  <>
		  <p className="welcome-form-label">Qual o seu CPF?</p>
		  <InputMask
			  mask="___.___.___-__"
			  replacement={{ _: /\d/ }}
			  name="cpf"
			  data-testid="cpf-input"
			  className={`default-input ${invalidCPF ? "input-invalid" : ""}`}
			  disabled={loadingForm}
			  onChange={(e) => {
				  if (
					  removerCaracteresEspeciais(customerData.cpf) ===
					  removerCaracteresEspeciais(e.target.value)
				  )
					  return;
				  changeCustomerData(e);
				  setInvalidCPF(false);
			  }}
		  />
		  {invalidCPF && (
			  <FormHelperText
				  error={true}
				  className="font-medium"
				  style={{ fontFamily: "Outfit, sans-serif" }}
			  >
				  CPF inválido
			  </FormHelperText>
		  )}
		  {loadingForm && <span className="searching-cpf-span">Buscando...</span>}
		  <GeneralWelcomeComponents.GeneralTermsOfUse customerData={customerData} containerProps={{ style: { marginTop: "8px" } }}/>
	  </>
  );
}

export default CpfForm;
