import generalLeadsApi from "./api.js";

export const saveLead = async ({ cpf, name, email, phone, bornAt, gender , businessGroupId}) => {
	const res = await generalLeadsApi.post(`leads/create`, {
		cpf,
		name,
		email,
		phone,
		bornAt,
		gender,
		businessGroupId
	});
	return res.data;
}

export const saveLeadSegment = async ({ segmentGroupIds, checkInId }) => {
	const res = await generalLeadsApi.post(`leads/check_in/segments/save`, {
		segmentGroupIds,
		checkInId
	});
	return res.data;
}
export const saveBrands = async ({ checkInId, brandIds, others }) => {
	const res = await generalLeadsApi.post(`leads/check_in/brands/save`, {
		checkInId,
		brandIds,
		others
	});
	return res.data;
}