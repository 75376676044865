import {useEffect, useState} from "react";
import { getBrands } from "../../../../../services/apis/generalLeads/fetchs.js";

function useBrandSelectList() {
	const [brandList, setBrandList] = useState([]);
	const [isLoadingBrands, setIsLoadingBrands] = useState(true);
	const [error, setError] = useState(null);

	const fetchBrandList = async () => {
		try {
			setIsLoadingBrands(true);
			const res = await getBrands();
			console.log("res", res);
			setBrandList(res?.data?.brands || []);
		} catch (err) {
			console.error(err.message);
			setError(err.message);
		} finally {
			setIsLoadingBrands(false);
		}
	}

	useEffect(() => {
		fetchBrandList().then();
	}, []);

	return { brandList, isLoadingBrands, error, fetchBrandList };
}

export default useBrandSelectList;