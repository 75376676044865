import React from "react";
import "./styles.scss";
import CreatableSelect from "react-select/creatable";
import useBrandSelectList from "./hook/useBrandSelectList.jsx";

function BrandSelect({ selectedBrands, setSelectedBrands }) {
	const { brandList, isLoadingBrands } = useBrandSelectList();

	return (
	  <div className="brand-select-container">
		  <p className="brand-select-label">Quais marcas você gostaria de receber ofertas?</p>
		  <CreatableSelect
			  isMulti
			  value={selectedBrands}
			  closeMenuOnSelect={false}
			  isLoading={isLoadingBrands}
			  placeholder="Comece a digitar"
			  noOptionsMessage={() => ""}
			  loadingMessage={() => "Carregando..."}
			  formatCreateLabel={(value) => <span>{`Criar "${value}"`}</span>}
			  onChange={(value) => setSelectedBrands(value)}
			  styles={{container: (base) => ({...base, width: "100%"})}}
			  options={brandList.map((item) => ({ value: item.id, label: item.name }))}
		  />
	  </div>
  );
}

export default BrandSelect;
