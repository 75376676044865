import React from "react";
import "./styles.scss";
import { InputMask } from '@react-input/mask';
import { Checkbox, FormHelperText } from "@mui/material";
import termosUso from "../../../../assets/documents/termo-de-uso.pdf";
import SelectDateSplitted from "../../../../components/SelectDateSplitted/index.jsx";
import validarPoliticaPrivacidade from "../../../../assets/documents/politica-privacidade.pdf";

function DefaultOptinForm({changeCustomerData, invalidPhone, setCustomerData, customerData, formError}) {
	return (
		<>
			<p className="welcome-form-label margin-top-0">
				Como gostaria de ser chamado? (*)
			</p>
			<input
				name="name"
				placeholder="Seu nome"
				className="default-input"
				onChange={(e) => changeCustomerData(e)}
			/>

			<p className="welcome-form-label">Qual o seu e-mail?</p>
			<input
				name="email"
				type="email"
				placeholder="Seu email"
				className="default-input"
				onChange={(e) => changeCustomerData(e)}
			/>

			<p className="welcome-form-label">Qual o seu celular? (*)</p>
			<InputMask
				mask="(__) _____-____"
				replacement={{ _: /\d/ }}
				name="phone"
				id="phone-input"
				data-testid="phone-input"
				className={`default-input ${
					invalidPhone ? "input-required-invalid" : ""
				}`}
				placeholder="(61) 99999-9999"
				onChange={(e) => changeCustomerData(e)}
			/>
			{invalidPhone && (
				<FormHelperText
					error={true}
					className="font-medium"
					style={{fontFamily: "Outfit, sans-serif"}}
				>
					Número inválido
				</FormHelperText>
			)}
			<p className="welcome-form-label">Qual sua data de nascimento?</p>
			<SelectDateSplitted
				selectedDate={customerData.bornAt}
				onDateChange={(date) => {
					setCustomerData((old) => ({...old, bornAt: date}));
				}}
			/>
			<div
				className="privacy-policy-old-container"
			>
				<Checkbox size="small" name="useTerms" checked={customerData.useTerms}
				          onChange={(e) => changeCustomerData(e, true)}/>
				<div className="privacy-policy-text-container">
					Concordo com os{" "}
					<a
						id={`btn-signup-useTerms`}
						className="button-privacy-link color-blue"
						target="_blank"
						href={termosUso}
						rel="noopener noreferrer"
					>
						{" "}
						Termos de uso{" "}
					</a>
					<span>e </span>
					<a
						id={`btn-signup-privacy-policy`}
						className="button-privacy-link color-blue"
						target="_blank"
						href={validarPoliticaPrivacidade}
						rel="noopener noreferrer"
					>
						Política de Privacidade
					</a>
				</div>
			</div>
			{!!formError && (
				<span className="input-error-msg anime-scale-in-center" style={{ margin: "8px 0" }}>
          {formError}
        </span>
			)}
		</>
	);
}

export default DefaultOptinForm;
