import React, { useEffect, useState } from "react";
import OfferList from "./OfferList";
import OfflineHeader from "./OfflineHeader";
import { useParams } from "react-router";
import { getUrlImagesFB } from "../../services/firebase";
import imgLoading from "../../assets/imgs/loading.svg";
import { getBusinessPage } from "../../graphql/Query/business";
import "./styles.css";

export default function Offers() {
  const params = useParams();
  const [businessData, setBusinessData] = useState({});

  useEffect(() => {
    const loadBusinessData = async () => {
      const id = parseInt(params.businessId);
      const res = await getBusinessPage({ id });
      const business = res.Business[0];
      const image = await getUrlImagesFB(business.page?.logo);
      setBusinessData({ name: business.name, logoImage: image });
    };

    loadBusinessData();
  }, [params.businessId]);

  return (
    <>
      <OfflineHeader
        image={businessData?.logoImage ?? imgLoading}
        text={businessData?.name}
      />
      <OfferList businessId={params.businessId} />
    </>
  );
}
