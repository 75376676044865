import React from "react";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
} from "react-router";
import {
  GeneralBrands,
  GeneralWelcome,
  GoodBye,
  NotFound,
  OffersToCustomer,
  Welcome,
  WelcomeSuccess,
  GenBiudCoupon,
} from "./pages";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route errorElement={<NotFound />}>
      <Route path="/welcome/:id/:type?" element={<Welcome />} />
      <Route path="/genbiud/coupon/:id" element={<GenBiudCoupon />} />
      <Route path="/hotlead/welcome/:id" element={<GeneralWelcome />} />
      <Route path="/goodbye/:id/:cpf/:type?" element={<GoodBye />} />
      <Route path="/goodbye/group/:id/:cpf/:type?" element={<GoodBye />} />
      <Route
        path="/hotlead/gsdepartments/:businessId"
        element={<GeneralBrands />}
      />
      <Route path="/success/:businessId" element={<WelcomeSuccess />} />
      <Route path="/hotlead/success/:businessId" element={<WelcomeSuccess />} />
      <Route
        path="/offersCustomer/:businessId"
        element={<OffersToCustomer />}
      />
    </Route>
  ), { future: { v7_relativeSplatPath: true } }
);

export default router;
