import axios from "axios";

const biudApi = axios.create({
	baseURL: import.meta.env.VITE_API_URL,
});

biudApi.interceptors.request.use(async (config) => {
	return config;
});

export default biudApi;
