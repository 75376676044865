import React, {useEffect, useState} from "react";
import "./styles.scss";
import Components from "./components";
import { LinearProgress } from "@mui/material";
import {external} from "../../services/linkHelper.js";
import { useLocation, useNavigate, useParams } from "react-router";
import {getSegmentsGroups} from "../../services/apis/generalLeads/fetchs.js";
import {saveBrands, saveLeadSegment} from "../../services/apis/generalLeads/requests.js";
import mallsLinks from "../Welcome/Success/GeneralSucessPage/mallUrlPerGroupId.json";
import BrandSelect from "./components/BrandSelect/index.jsx";
import {getCurrentEnvironment} from "../../services/utils.jsx";

function GeneralBrands() {
	const location = useLocation();
	const params = useParams();

	const data = location.state;
	const requestData = data?.requestData || {};
	const checkInData = data?.checkInData || {};

	const navigate = useNavigate();
	const [search, setSearch] = useState("");
	const [selectedSegments, setSelectedSegments] = useState([]);
	const [requestLoading, setRequestLoading] = useState(false);
	const [selectedBrands, setSelectedBrands] = useState([]);

	const [segmentsList, setSegmentsList] = useState([]);
	const [loadingSegments, setLoadingSegments] = useState(true);

	const [error, setError] = useState("");

	const fetchSegmentsList = async () => {
		try {
			setLoadingSegments(true);
			const res = await getSegmentsGroups();
			setSegmentsList(res?.data?.segmentsGroup || []);
		} catch (err) {
			console.error(err.message);
		} finally {
			setLoadingSegments(false);
		}
	};

	const requestSaveSegments = async () => {
		try {
			setError("");

			if (!selectedSegments?.length) {
				setError("Você precisa selecionar pelo menos uma categoria!");
				return;
			}

			setRequestLoading(true);
			const checkInId = checkInData?.id || 0;
			const resSegments = await saveLeadSegment({
				checkInId: checkInId,
				segmentGroupIds: selectedSegments,
			});

			if (selectedBrands.length > 0) {
				await saveBrands({
					checkInId: checkInId,
					brandIds: selectedBrands.filter((item) => !item?.__isNew__).map((item) => item.value),
					others: selectedBrands.filter((item) => !!item?.__isNew__).map((item) => item.value),
				});
			}

			if (resSegments.data?.message === "Segments saved.") {
				if (location.hash !== '#totem') {
					window.location.href = mallsLinks[params.businessId];

					return;
				}

				navigate(`${external.GSSUCCESS}/${data.id}${location.hash}`, {
					state: {
						cpf: requestData.cpf,
						name: requestData?.name || "",
						isNewOptIn: true,
						isGeneral: true,
					},
				});
			}
		} catch (err) {
			console.error(err.message);
			setError("Ops, tivemos um problema, tente novamente mais tarde.");
		} finally {
			setRequestLoading(false);
		}
	};

	useEffect(() => {
		if (!data?.requestData?.cpf) {
			if (params.businessId) {
				navigate(`${external.GSWELCOME}/${params.businessId}`);
				return;
			}
			navigate(`${external.GSWELCOME}`);
		}
		fetchSegmentsList().then();
	}, []);

	const filteredDepartments = search
		? segmentsList.filter((brand) =>
			brand.name.toUpperCase().includes(search.toUpperCase())
		)
		: segmentsList;

	if (loadingSegments)
		return (
			<LinearProgress
				sx={{
					backgroundColor: "white",
					"& .MuiLinearProgress-bar": {backgroundColor: "#C4C4C4"},
				}}
			/>
		);

	return (
		<section className="general-brands-container">
			<Components.Header
				title={`Olá, ${data?.name || ""}!`}
				subtitle="Escolha as categorias que você quer receber ofertas exclusivas hoje:"
			/>
			<Components.BrandsList>
				{filteredDepartments.length ? (
					filteredDepartments.map((brand) => (
						<Components.BrandCard
							key={brand.id}
							id={brand.id}
							brandTitle={brand.name}
							data-testid="brand-card"
							onClick={() =>
								setSelectedSegments((old) => {
									if (old.includes(brand.id)) {
										return old.filter((item) => item !== brand.id);
									}
									return [...old, brand.id];
								})
							}
							className={
								selectedSegments.includes(brand.id) ? "brand-item-selected" : ""
							}
						/>
					))
				) : (
					<span className="gs-brand-list-item-notfound">
            Nenhum departamento encontrado
          </span>
				)}
			</Components.BrandsList>
			{getCurrentEnvironment() !== "produção" && (
				<BrandSelect selectedBrands={selectedBrands} setSelectedBrands={setSelectedBrands}/>
			)}
			{error && <span className="msg-alert-container anime-slit-in-hr text-center">{error}</span>}
			<Components.Footer
				loading={requestLoading}
				buttonAction={() => requestSaveSegments()}
			/>
		</section>
	);
}

export default GeneralBrands;
