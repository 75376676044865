import React from "react";
import "./styles.scss";
import PropTypes from "prop-types";
import BiudButton from "../../../../components/BiudButton/index.jsx";
import {CircularProgress} from "@mui/material";


function Footer({ buttonAction=()=>"", loading = false }) {
  return (
	  <footer className="gs-brands-footer-container">
		  <BiudButton
			  buttonType="warning"
			  size="lg"
			  className="btn"
			  onClick={buttonAction}
			  style={{width: "auto", padding: "8px 16px", minWidth: "max-content"}}
		  >
			  {loading ? <CircularProgress size={24} style={{color: "#000"}} /> : "Finalizar"}
		  </BiudButton>
	  </footer>
  );
}

Footer.propTypes = {
	selectedBrands: PropTypes.arrayOf(PropTypes.number),
	buttonAction: PropTypes.func,
	loading: PropTypes.bool,
};

export default Footer;
