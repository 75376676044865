import generalLeadsApi from "./api.js";

export const getLead = async ({ cpf, businessGroupId }) => {
	const res = await generalLeadsApi.get(`leads/find_or_create/${cpf}/${businessGroupId}`);
	return res.data;
}

export const getLeadSegments = async ({ cpf }) => {
	const res = await generalLeadsApi.get(`leads/${cpf}/segments`);
	return res.data;
}

export const getActiveLeads = async () => {
	const res = await generalLeadsApi.get(`leads/active/list`);
	return res.data;
}

export const getSegments = async () => {
	const res = await generalLeadsApi.get(`leads/check_in/segments/list`, {
		headers: {
			"Content-Type": "application/json",
		},
	});
	return res.data;
}

export const getSegmentsGroups = async () => {
	const res = await generalLeadsApi.get(`leads/check_in/segments/group/list`, {
		headers: {
			"Content-Type": "application/json",
		},
	});
	return res.data;
}

export const getBrands = async () => {
	const res = await generalLeadsApi.get(`leads/check_in/brands/list`, {
		headers: {
			"Content-Type": "application/json",
		},
	});
	return res.data;
}

export const leadCheckIn = async ({ cpf, businessGroupId }) => {
	// const res = await generalLeadsApi.get(`leads/${businessGroupId}/${cpf}/check_in`);
	const res = await generalLeadsApi.get(`leads/${cpf}/check_in/${businessGroupId}`);
	return res.data;
}


export const getHotLeadBusinessGroupConfig = async ({ businessGroupId, params }) => {
	const res = await generalLeadsApi.get(`leads/${businessGroupId}/config`, { params });
	return res.data;
};